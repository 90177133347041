body {
  min-height: 100%;
  background-color: #ffffff;
  font-family: "Inter", sans-serif;
  color: rgb(37, 37, 38);
  font-size: 1em;
  line-height: 140%;
  margin: 0px;
}

a {
  color: #f10965 !important;
}

a:hover {
  color: #f10965 !important;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  color: #1e283b;
  width: 300px;
}

button[type="submit"] {
  padding: 10px;
  background-color: #f10965;
  color: #fff;
  border: none;
  cursor: pointer;
}

.want-talk-items {
  color: #bfbfbf !important;
}

.want-talk-items:hover {
  color: #f10965 !important;
}

.simple-highlight {
  color: #f10965;
  font-weight: bold;
}

.background-scene {
  position: fixed;
  top: 0px;
  z-index: -1;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.horizontal-line {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 1px;
  background-color: #bfbfbf;
}

.horizontal-line._10-percent {
  top: 10%;
  background-color: #f6f6f6;
}

.horizontal-line._20-percent {
  top: 20%;
  background-color: #f6f6f6;
}

.horizontal-line._30-percent {
  top: 30%;
  background-color: #f6f6f6;
}

.horizontal-line._40-percent {
  top: 40%;
  background-color: #f6f6f6;
}

.horizontal-line._50-percent {
  top: 50%;
  background-color: #f6f6f6;
}

.horizontal-line._60-percent {
  top: 60%;
  background-color: #f6f6f6;
}

.horizontal-line._10-percent {
  top: 10%;
  background-color: #f6f6f6;
}

.horizontal-line._70-percent {
  top: 70%;
  background-color: #f6f6f6;
}

.horizontal-line._80-percent {
  top: 80%;
  background-color: #f6f6f6;
}

.horizontal-line._90-percent {
  top: 90%;
  background-color: #f6f6f6;
}

.vertical-line {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1px;
  height: 100vh;
  background-color: #bfbfbf;
}

.vertical-line._20-percent {
  left: 20%;
  background-color: #f6f6f6;
}

.vertical-line._40-percent {
  left: 40%;
  background-color: #f6f6f6;
}

.vertical-line._60-percent {
  left: 60%;
  background-color: #f6f6f6;
}

.vertical-line._80-percent {
  left: 80%;
  background-color: #f6f6f6;
}

.hero-heading {
  line-height: 80% !important;
  font-weight: 900 !important;
  letter-spacing: -0.04em !important;
}

.hero-skills {
  position: relative !important;
  margin-top: 13.5vh !important;
  margin-bottom: 27vh;
  color: #dcdcdc !important;
  letter-spacing: -0.05em !important;
  font-size: 5vh !important;
  font-weight: 800 !important;
}

.hero-skills-item {
  position: relative !important;
  color: #dcdcdc !important;
  letter-spacing: -0.05em !important;
  font-size: 4vh !important;
  font-weight: 800 !important;
}

.hero-skills-item-desc {
  position: relative !important;
  letter-spacing: -0.05em !important;
  color: #000000 !important;
  font-size: 2vh !important;
  font-weight: 400 !important;
}

.contact-me-item {
  position: relative !important;
  color: #dcdcdc !important;
  letter-spacing: -0.05em !important;
  font-weight: 800 !important;
}

.contact-me-item a {
  color: #dcdcdc !important;
}

.contact-me-item a:hover {
  color: #f10965 !important;
}

.bio-history {
  position: static !important;
  top: 20vh !important;
  min-width: 320px !important;
  margin-bottom: 0 !important;
  font-size: 1.4em !important;
  line-height: 170% !important;
  letter-spacing: -0.04em !important;
}

.history-heading {
  color: #000000 !important;
  letter-spacing: -0.04em !important;
  font-weight: 900 !important;
}

.benefits-heading {
  position: relative !important;
  letter-spacing: -0.07em !important;
  font-size: 3vh !important;
  font-weight: 800 !important;
}

.benefits-heading-weeks {
  position: relative !important;
  letter-spacing: -0.07em !important;
  font-size: 3vh !important;
  font-weight: 800 !important;
}

.what-i-do-heading {
  color: #000000 !important;
  letter-spacing: -0.04em !important;
  font-weight: 900 !important;
}

.post-heading {
  color: #000000 !important;
  letter-spacing: -0.04em !important;
  font-weight: 900 !important;
}

.latest-post-heading {
  color: #000000 !important;
  letter-spacing: -0.04em !important;
  font-weight: 900 !important;
}

.where-heading {
  color: #000000 !important;
  letter-spacing: -0.04em !important;
  font-weight: 900 !important;
}

.bio-history-desc {
  color: #000000 !important;
}

.navbar {
  position: relative !important;
  margin-top: 5vh !important;
  margin-bottom: 0vh !important;
  letter-spacing: -0.05em !important;
  font-size: 3vh !important;
  font-weight: 800 !important;
}

.title-heading {
  position: relative !important;
  width: 60vw !important;
  margin-bottom: 10vh !important;
  color: #000000 !important;
  letter-spacing: -0.05em !important;
  text-shadow: rgba(0, 0, 0, 0.12) 0px 32px 64px !important;
  line-height: 97% !important;
  letter-spacing: -0.05em !important;
  font-weight: 900 !important;
}

.my-history-box {
  background-color: #ffffff;
}

.contact-heading {
  line-height: 80% !important;
  font-weight: 900 !important;
  letter-spacing: -0.05em !important;
}

.image-events {
  filter: grayscale(100) !important;
}

.image-events:hover {
  filter: grayscale(0) !important;
}

.footer-heading {
  color: #ffffff !important;
  letter-spacing: -0.05em !important;
  line-height: 97% !important;
  font-weight: 900 !important;
  font-size: 3vh !important;
}

.post-title {
  position: relative !important;
  margin-top: 1vh !important;
  margin-bottom: 3vh !important;
  color: #1e283b !important;
  letter-spacing: -0.05em !important;
  font-size: 4vh !important;
  font-weight: 800 !important;
}

.card-zoomer {
  transition: transform 0.5s;
}

.card-zoomer:hover {
  -ms-transform: scale(1.05); /* IE 9 */
  -webkit-transform: scale(1.05); /* Safari 3-8 */
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.blog-date {
  color: #c0babc !important;
  letter-spacing: -0.05em !important;
  font-weight: 800 !important;
}

.academy-format-item {
  position: relative !important;
  color: #dcdcdc !important;
  letter-spacing: -0.05em !important;
  font-size: 5vh !important;
  font-weight: 800 !important;
}

.academy-pricing {
  position: relative !important;
  color: #c9e500 !important;
  letter-spacing: -0.05em !important;
  font-size: 5vh !important;
  font-weight: 800 !important;
}

.tachado_rojo {
  color: #f00;
  text-decoration: line-through;
}
.tachado_rojo span {
  color: #000;
}

.academy-formt-title {
  color: #1e283b !important;
  letter-spacing: -0.07em !important;
  font-size: 3vh !important;
  font-weight: 900 !important;
}

.card-zoomer-soft {
  transition: transform 0.5s;
}

.card-zoomer-soft:hover {
  filter: grayscale(0) !important;
  -ms-transform: scale(1.02); /* IE 9 */
  -webkit-transform: scale(1.02); /* Safari 3-8 */
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.card {
  width: 100%;
  height: 100%;
  background: #1f2937;
  color: #ffffff !important;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
}

.card p {
  z-index: 1;
  color: #fff;
  font-size: 1em;
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.card h2 {
  z-index: 1;
  color: #fff;
}

.card span {
  z-index: 1;
  color: #1f2937;
  font-size: 1em;
}

.card input {
  z-index: 1 !important;
  color: #fff;
}

.raap-subtitle {
  color: #f10965 !important;
  font-weight: bold;
}

.information-heading {
  line-height: 100% !important;
  font-weight: 900 !important;
  letter-spacing: -0.04em !important;
  color: #fff;
}

.week-details {
  line-height: 100% !important;
  font-weight: 400 !important;
  letter-spacing: -0.04em !important;
  color: gray;
}

/* Dotted border */
hr.dotted {
  border-top: 1px dotted #bbb;
  margin-top: 15px;
  margin-bottom: 10px;
}

.card-more-info {
  border-radius: 4px;
  background: #1f2937 !important;
  color: #ffffff !important;
}

.card-more-info p {
  z-index: 1;
  color: #ffffff;
  font-size: 1em;
  margin-top: 2px !important;
  margin-bottom: 5px !important;
}

.card-more-info h2 {
  z-index: 1;
  color: #fff;
}

.card-more-info span {
  z-index: 1;
  color: #1f2937;
  font-size: 1em;
}

.card-more-info input {
  color: #1f2937;
  border-radius: 4px;
  width: 100%;
}

.card-more-info button[type="submit"] {
  padding: 10px;
  background-color: #c9e500;
  color: #ffffff;
  font-weight: 800;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.card-more-info-sec {
  padding: 10px;
  color: #ffffff;
  background-color: #c9e500;
  font-weight: 800;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.card-dossier {
  border-radius: 4px;
  background: #1f2937;
  color: #ffffff;
}

.card-dossier input {
  color: #1f2937;
  border-radius: 4px;
  width: 100%;
}

.card-dossier button[type="submit"] {
  padding: 10px;
  background-color: #f10965;
  color: #ffffff;
  font-weight: 800;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.bookme {
  padding: 15px;
  background-color: #ebebeb !important;
  color: #1f2937 !important;
  font-weight: 800;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  margin-top: 10px;
}

.the-teacher-title {
  position: relative !important;
  margin-top: 1vh !important;
  margin-bottom: 3vh !important;
  color: #d5d5d5 !important;
  letter-spacing: -0.05em !important;
  font-size: 4vh !important;
  font-weight: 800 !important;

}
